import React, { useState, useEffect } from "react"
import Header from '../../templates/GreenwaveHeader/index';
import NewBundleSection from './sections/newBundleSection';
import CompleteOfferSection from './sections/completeOfferSection';
import styled from 'styled-components';
import BannerSection from './sections/bannerSection';
import './sections/Loading.scss';
import ErrorModule from '../../molecules/ErrorModule/index';

const Background = styled.div`
    background-color: ${({ backgroundColor }) => backgroundColor};
    width: 100%;
    padding-top: 120px;
`;
const LoadingContainer = styled.div`
    background-color: rgba(46, 49, 49, .25);
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
`;

const OfferPage = () => {
  const [selectedBundle, setSelectedBundle] = useState(false)
  const [selectedBundleData, setSelectedBundleData] = useState('')
  const [debtData, setDebtData] = useState('')
  const [offerIdPresent, setOfferIdPresent] = useState(false)
  let offer;


  const getData = async () => {
    try {
      const response = await fetch(`https://api.traversehealthdemo.com/api/offer-detail/${offer}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        // Handle HTTP errors
        console.error(`HTTP error! status: ${response.status}`);
        return;
      }
      const result = await response.json(); // Parse the JSON data
      console.log(result);
      const indices = [1, 2, 3];
      const offerData = indices.map(i => ({
          noOfPayments: result[`noOfPayments${i}`],
          frequency: "monthly",
          installmentAmount: result[`installmentAmount${i}`],
          discount: result[`discount${i}`],
          saving: result[`saving${i}`],
          remainingAmount: result['remainingAmount'],
          expirationDate: result['expirationDate'],
          offerCode: result['offerCode']
      }));
      result.offerData = offerData
      setDebtData(result); // Use the parsed data
    } catch (error) {
      console.error('Fetch error:', error);
    }
  }

  useEffect(()=> {
    offer = window.location.href.split('=')[1];
    if (offer !== undefined) {
      getData();
      setOfferIdPresent(true)
    }
  }, [selectedBundle])

  const onSelectOfferHandler = offer => {
    setSelectedBundle(true)
    console.log("offer:", offer)
    setSelectedBundleData(offer)
  };

  const backToOffersHandler = () => {
    setSelectedBundle(false)
  }

  return (
    <>
    {!offerIdPresent ? <ErrorModule errorText="No Offer found, please follow the link in your email to display offers." />
    :
    <Background backgroundColor={selectedBundle ? "#E4ECF5" : "white"} style={{"height": !selectedBundle ? '100%' : 'calc(100vh - -120px)'}}>
    {debtData ?
    <>
      <Header />
      {!selectedBundle ?
      <>
        <BannerSection data={debtData}  />
        <NewBundleSection data={debtData.offerData} onSelectOffer={onSelectOfferHandler} />
      </>
      :
        <>
          <CompleteOfferSection selectedOffer={selectedBundleData} goBackToOffers={backToOffersHandler} />
          {/* <div style={{ background: '#E4ECF5', width: '100%', height: '100%', position: 'fixed', bottom: 0}}/> */}
        </>
      }
    </>
    :
      <LoadingContainer>
        <div className="loading-container-demo">
            <div className="loading-demo"></div>
        </div>
      </LoadingContainer>
    }
    </Background>
    }
    </>
  )
}

export default OfferPage;
